import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Paragraph from "../components/Paragraph"
import Seo from "../components/seo"
import Title from "../components/Title"
import rehypeReact from "rehype-react"
import ContactForm from "../components/ContactForm"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {"p": Paragraph},
}).Compiler

const ContactPage = ({data}) => {
  const {markdownRemark: markdown} = data
  
  return <Layout>
    <Seo title={markdown.frontmatter.title} />
    <div className="lg:flex lg:flex-row lg:justify-between">
        <div className="lg:w-1/3">
            <Title>
            {markdown.frontmatter.title}
            </Title>
        
            {renderAst(markdown?.htmlAst)}
        </div>

        <ContactForm className="lg:w-1/2"></ContactForm>
    </div>
    
  </Layout>
}

export const query = graphql`
query {
  markdownRemark(fields: { slug: { eq: "/contact/" } }) {
    htmlAst
    frontmatter {
      title
    }
  }
}
`

export default ContactPage
